import classNames from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import FieldEmail from 'components/FieldEmail'
import FieldFirstName from 'components/FieldFirstName'
import type { Component } from 'constants/types'
//import { useGlobalContext } from 'contexts/GlobalContext'
import * as validations from 'libs/validations'

type Props = {
  buttonCopy: string
  onSubmit: () => void
} & Component

export default function NameEmailForm({ buttonCopy, className, onSubmit, ...props }: Props) {
  //const globalContext = useGlobalContext()

  function handleSubmit(values) {
    onSubmit(values)
  }

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        //email: globalContext.user.email || '',
        email: '',
        //firstName: globalContext.user.firstName || '',
        firstName: '',
      }}
      validateOnBlur={false}
      validationSchema={Yup.object({
        email: validations.EMAIL,
        firstName: validations.NAME,
      })}
      className={classNames('NameEmailForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FieldFirstName />
          <FieldEmail />
          <Button
            color="yellow"
            disabled={formikProps.isSubmitting}
            full
            onClick={formikProps.submitForm}>
            {buttonCopy}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
